import { SCREENS, SCREEN_SWITCH_DURATION, d } from "./utils";
/** @jsxRuntime classic /
/* @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";

import React, { useCallback } from "react";
import categories from "./categories";
import colors from "../../common/colors";
import i18n from "../../../../i18n";
import styled from "@emotion/styled";
import { transparentize } from "polished";
import { getCategories } from "../../services";

const holderTrans = {};
holderTrans[SCREENS.SPLASH] = css`
  top: 100%;
`;
holderTrans[SCREENS.CATEGORY_SELECTION] = css`
  top: 0;
`;
holderTrans[SCREENS.HIGHLIGHT] = css`
  top: 0;
`;
const Holder = styled.div`
  position: absolute;
  left: ${d.sideGap};
  right: ${d.sideGap};
  bottom: 0;
  ${p => holderTrans[p.screen] || holderTrans[SCREENS.SPLASH]}
`;
const CategorySection = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100vh + 10px);
  height: calc(56vh);
  transform: translateY(-20px);
  transition: ${SCREEN_SWITCH_DURATION}ms all;
  z-index: 15;
  display: flex;
`;
const CategoryColumn = styled.div`
  width: 50%;
  &:first-of-type {
    padding-right: 9px;
    div:first-of-type {
      height: 50%;
    }
  }
  &:last-of-type {
    padding-left: 9px;
  }
  & > div {
    &:last-of-type {
      button {
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.2);
      }
    }
  }
`;

const CategoryHolder = styled.div`
  height: 25%;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
`;
const Category = styled.button`
  border-radius: 20px;
  border: 0;
  outline: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  top: 10px;
  width: 100%;
  background-image: url("${p => p.image}");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 14px;
  align-items: left;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 20px;
    border: 3px solid ${colors.redRibbon};
    opacity: 0;
    transition: 1s opacity;
  }
  &.selected:before {
    opacity: 1;
  }
`;
const CategoryActionIcon = styled.span`
  position: relative;
  width: 27px;
  height: 27px;
  z-index: 1;
  margin-bottom: 12px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 50%;
    background-color: ${transparentize(0.8, colors.white)};
    backdrop-filter: blur(30px);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/static/images/buzzstorm/icon-plus.svg");
  }
  &:after {
    z-index: 1;
    background-image: url("/static/images/buzzstorm/icon-tick.svg");
    background-size: 65%;
    background-color: ${colors.redRibbon};
    opacity: 0;
    transform: rotate(-90deg);
    transition: opacity 1s, transform 1s;
  }
  &.selected {
    &:after {
      transform: rotate(0deg);
      opacity: 1;
    }
  }
`;
const CategoryLabel = styled.label`
  font-size: 16px;
  font-weight: 800;
  line-height: 1.38;
  letter-spacing: -0.64px;
  text-align: left;
  color: #f4f4f4;
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10vh;
  transition: ${SCREEN_SWITCH_DURATION}ms height;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  overflow: hidden;
  z-index: 5;
`;
const TitleHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10%;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -1.28px;
  text-align: left;
  color: ${colors.white};
  margin: 0;
`;
const ButtonLater = styled.button`
  border: 0;
  padding: 0;
  outline: 0;
  background-color: transparent;
  text-decoration: underline;
  color: ${colors.silver};
  font-size: 12px;
  font-weight: 800;
  line-height: 1.17;
  white-space: nowrap;
`;
const SelectedLabel = styled.label`
  color: ${colors.white};
  font-size: 12px;
  font-weight: 800;
  line-height: 1.17;
  white-space: nowrap;
`;
const categorySectionTransitions = {
  [SCREENS.CATEGORY_SELECTION]: css`
    top: calc(${d.sideGap});
  `,
  [SCREENS.CATEGORY_SELECTION + 1]: css`
    top: -80vh;
    opacity: 0;
  `
};

const contentTransitions = {};
contentTransitions[SCREENS.CATEGORY_SELECTION] = css`
  height: 20vh;
`;
contentTransitions[SCREENS.HIGHLIGHT] = css`
  height: 0;
`;

const categoryFadeInAnimations = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const categoryFadeOutAnimations = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
`;

const TOTAL_VISIBLE_CATEGORY = 7;

const CategorySelector = props => {
  const { t, screen, setScreen, setParentSelectedCategories } = props;
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [prevCategoryList, setPrevCategoryList] = React.useState([]);

  const gridCategories = React.useRef(
    categories.filter(c => c.type !== "portrait")
  );
  const portraitCategories = React.useRef(
    categories.filter(c => c.type === "portrait")
  );

  const initialiseCategories = useCallback(() => {
    const items = [],
      prevItems = [];
    for (let i = 0; i < TOTAL_VISIBLE_CATEGORY; i++) {
      if (i === 0) {
        items.push(portraitCategories.current.shift());
      } else {
        items.push(gridCategories.current.shift());
      }
      prevItems.push(null);
    }
    setCategoryList(items);
    setPrevCategoryList(prevItems);
  }, []);

  React.useEffect(() => {
    initialiseCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchData = async () => {
      try {
        const allCategories = await getCategories();
        const modifiedCategories = [];
        categories.forEach(category => {
          const common = allCategories.find(cat => cat.id === category.id);
          if (common) {
            modifiedCategories.push({
              ...common,
              imageUrl: category.image,
              displayName: category.text,
              type: category.type,
              parent: category.parent
            });
          }
        });
        gridCategories.current = modifiedCategories.filter(
          c => c.type !== "portrait"
        );
        portraitCategories.current = modifiedCategories.filter(
          c => c.type === "portrait"
        );
        initialiseCategories();
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCategorySelect = React.useCallback(
    e => {
      const ele = e.currentTarget;
      const id = ele.dataset.id;
      if (!selectedCategories.includes(id)) {
        setSelectedCategories([...selectedCategories, id]);
        setParentSelectedCategories([...selectedCategories, id]);
      }
    },
    [selectedCategories, setParentSelectedCategories]
  );

  const onSelect = React.useCallback(
    e => {
      const ele = e.currentTarget;
      if (ele === e.target) {
        const position = +ele.dataset.position;
        const id = ele.dataset.id;

        let nextCategory;
        if (position === 0) {
          nextCategory = portraitCategories.current.find(
            cat => cat.parent === id
          );
        } else {
          nextCategory = gridCategories.current.find(cat => cat.parent === id);
        }

        if (nextCategory) {
          const newList = categoryList.map((item, index) =>
            index === position ? nextCategory : item
          );
          const newPrevList = prevCategoryList.map((item, index) =>
            index === position ? categoryList[index] : item
          );
          setCategoryList(newList);
          setPrevCategoryList(newPrevList);
        }
      }
    },
    [categoryList, prevCategoryList]
  );

  const renderCategory = React.useCallback(
    (category, index) => {
      const prevCategory = prevCategoryList[index];
      let prevSelectedClassName = "",
        selectedClassName = "";

      if (prevCategory) {
        prevSelectedClassName = selectedCategories.includes(
          prevCategory && prevCategory.id
        )
          ? "selected"
          : "";
      }
      selectedClassName = selectedCategories.includes(category && category.id)
        ? "selected"
        : "";
      if (!category) {
        return null;
      }
      return (
        <CategoryHolder key={category.id}>
          <Category
            onClick={onCategorySelect}
            image={category.imageUrl}
            data-id={category.id}
            data-position={index}
            onTransitionEnd={onSelect}
            className={selectedClassName}
            css={
              prevCategory &&
              css`
                animation: ${categoryFadeInAnimations} 1s ease;
                animation-fill-mode: forwards;
              `
            }
          >
            {/* this is needed to adjust the text position */}
            {index === 0 && <div />}

            <CategoryActionIcon className={selectedClassName} />
            <CategoryLabel>{category.displayName}</CategoryLabel>
          </Category>
          {prevCategory && (
            <Category
              onClick={onCategorySelect}
              image={prevCategory.imageUrl}
              data-id={prevCategory.id}
              data-position={index}
              className={prevSelectedClassName}
              css={
                prevCategory &&
                css`
                  animation: ${categoryFadeOutAnimations} 1s ease;
                  animation-fill-mode: forwards;
                `
              }
            >
              {/* this is needed to adjust the text position */}
              {index === 0 && <div />}

              <CategoryActionIcon className={prevSelectedClassName} />
              <CategoryLabel>{prevCategory.text}</CategoryLabel>
            </Category>
          )}
        </CategoryHolder>
      );
    },
    [onCategorySelect, onSelect, prevCategoryList, selectedCategories]
  );

  const categoryColumns = categoryList.reduce(
    (agg, item, i) => {
      const index = i < Math.floor(TOTAL_VISIBLE_CATEGORY / 2) ? 0 : 1;
      agg[index].push(renderCategory(item, i));
      return agg;
    },
    [[], []]
  );

  return (
    <Holder {...props} data-testid="category-selector">
      <CategorySection screen={screen} css={categorySectionTransitions[screen]}>
        {categoryColumns.map((item, index) => (
          <CategoryColumn key={index}>{item}</CategoryColumn>
        ))}
      </CategorySection>
      <ContentHolder css={contentTransitions[screen]}>
        <TitleHolder>
          <Title>
            {t("Choose Your")}
            <br />
            {t("Interests")}
          </Title>
          <div>
            {selectedCategories.length > 0 ? (
              <SelectedLabel>
                {selectedCategories.length.toString().padStart(2, "0")} -{" "}
                {t("Selected")}
              </SelectedLabel>
            ) : (
              <ButtonLater onClick={() => setScreen(s => s + 1)}>
                {t("Do it later")}
              </ButtonLater>
            )}
          </div>
        </TitleHolder>
      </ContentHolder>
    </Holder>
  );
};

export default i18n.withTranslation("common")(CategorySelector);
