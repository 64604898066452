import { SCREENS, d } from "./utils";
/** @jsxRuntime classic /
/* @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";

import Clock from "../BuzzStorm/Onboarding/Clock";
import React from "react";
import styled from "@emotion/styled";

const palletes = [
  { angle: -10, point: 0.1, Y: 10 },
  { angle: -20, point: 0.2, Y: 20 },
  { angle: -34, point: 0.3, Y: 28 },
  { angle: -53, point: 0.4, Y: 25 },
  { angle: -61, point: 0.5, Y: 28 },
  { angle: -69, point: 0.6, Y: 29 },
  { angle: -76, point: 0.7, Y: 49 }
];

/*
0% {
    transform: translateY(-50%) scale(1) rotateX(-80deg);
  }
50% {
    transform: translateY(${p.Y - 50}%) scale(1) rotateX(${p.angle}deg);
  }
  80%, 95% {
    transform: translateY(-50%) rotateX(0deg) scale(1);
    transform-origin: 50% 125%;
    top: 50vh;
    left: calc(${d.sideGap} + 10%);
    right: calc(${d.sideGap} + 10%);
  }
  */
const palleteAnimation = p => keyframes`
  
  
  100% {
    transform: translateY(0) rotateX(0deg) scale(1);
    
    top: ${d.sideGap};
    left: ${d.sideGap};
    right: ${d.sideGap};
  }
`;

const holderTrans = {};
holderTrans[SCREENS.SPLASH] = css`
  top: 100%;
`;
holderTrans[SCREENS.GET_STARTED] = css`
  top: 0;
`;
holderTrans[SCREENS.LANGUAGE_SELECTION] = css`
  top: 0;
`;

const endTransition = css`
  transition: all 0.5s;
  transform: translate(0, -100%);
  opacity: 0;
`;
const Palette = styled.div`
  position: absolute;
  ${p => holderTrans[p.screen] || holderTrans[SCREENS.SPLASH]}
  left: 0;
  right: 0;
  bottom: 0;
  transform-style: preserve-3d;
  perspective: 200pc;
  perspective-origin: bottom;

  & > div {
    position: absolute;
    border-radius: 30px;
    top: 50%;
    left: calc(${d.sideGap} + 10%);
    right: calc(${d.sideGap} + 10%);
    height: calc(80% - 15%);

    transform-origin: 50% 155%;
    transform: translateY(0%) rotateX(-80deg);
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-delay: 6s;
    animation-duration: ${p => p.animationDuration - 2000}ms;

    & > img {
      width: 100%;
      max-width: 100%;
      height: auto;
      border-radius: 30px;
    }
  }
`;
const ImageItem = styled.div``;

const ImagePallete = props => {
  const { screen, images } = props;

  const animFn = palleteAnimation;
  return (
    <>
      <Palette
        data-testid="image-pallete"
        {...props}
        css={screen > SCREENS.GET_STARTED && endTransition}
      >
        {images.map((src, index) => {
          return (
            <ImageItem
              key={src}
              css={css`
                animation-name: ${screen > SCREENS.SPLASH
                  ? animFn(palletes[index])
                  : "none"};
                animation-duration: ${props.animationDuration - 1000}ms;
              `}
            >
              <img src={src} alt={`Screen ${index}`} />
              <Clock isSamsung />
            </ImageItem>
          );
        })}
      </Palette>
    </>
  );
};

export default React.memo(ImagePallete);
