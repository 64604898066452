import { SCREENS, d } from "./utils";
/** @jsxRuntime classic /
/* @jsx jsx */
import { css, jsx } from "@emotion/core";
import { selectCategories, selectLanguages } from "../../services";

import { Component } from "react";
import colors from "../../common/colors";
import { fireEvent } from "../../utils/tracking";
import i18n from "../../../../i18n";
import styled from "@emotion/styled";

const trans = {};

trans[SCREENS.SPLASH] = css`
  bottom: -100%;
`;
trans[SCREENS.GET_STARTED] = css`
  bottom: 0;
`;
trans[SCREENS.LANGUAGE_SELECTION] = trans[SCREENS.GET_STARTED];
trans[SCREENS.CATEGORY_SELECTION] = trans[SCREENS.GET_STARTED];

const Holder = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  ${p => trans[p.screen] || trans[SCREENS.SPLASH]}
  display: flex;
  justify-content: space-between;
  z-index: 5;
  margin: 0 ${d.sideGap} 48px;
  transition: 1s bottom;
`;

const DotHolder = styled.div`
  display: flex;
  align-items: center;
  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 1px solid ${colors.white};
    margin-right: 15px;
    transition: all 1s;
    &.active {
      background-color: ${colors.white};
      width: 10px;
      height: 10px;
    }
  }
  &.inverse {
    .dot {
      border-color: ${colors.black};
      &.active {
        background-color: ${colors.glanceRed};
        border-color: ${colors.glanceRed};
      }
    }
  }
`;
const NextButton = styled.button`
  border: 0;
  outline: 0;
  color: #f4f4f4;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: -0.42px;
  color: #f4f4f4;
  white-space: nowrap;
  height: 36px;
  padding-right: 47px;
  position: relative;
  background-color: transparent;
  &:after {
    content: "";
    position: absolute;
    width: 36px;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("/static/images/buzzstorm/icon-arrow-right-white.svg");
    background-size: 100%;
    transition: 1s background;
  }
  &.inverse {
    color: ${colors.mineShaft};
    &:after {
      background-image: url("/static/images/buzzstorm/icon-arrow-right-color.svg");
    }
  }
`;

class StepNavigator extends Component {
  changeScreen = async () => {
    const {
      setScreen,
      screen,
      languages,
      isUpdating = false,
      selectedCategories
    } = this.props;
    try {
      if (screen === SCREENS.LANGUAGE_SELECTION) {
        selectLanguages(languages);
        const currentScreen = isUpdating ? "UPDATING" : "ONBOARDING";
        fireEvent(
          "Language Screen",
          "Click - " + currentScreen,
          languages.join()
        );
      }
      if (screen === SCREENS.CATEGORY_SELECTION) {
        await selectCategories(selectedCategories);
        const currentScreen = isUpdating ? "UPDATING" : "ONBOARDING";
        fireEvent(
          "Category Screen",
          "Click - " + currentScreen,
          selectCategories
        );
      }
    } catch (err) {
      console.error(err);
    }
    setScreen(screen + 1);
  };
  render() {
    const { t, screen, animationDelay = 0 } = this.props;
    const isInverse = screen === SCREENS.LANGUAGE_SELECTION;

    const initCss =
      screen === SCREENS.GET_STARTED
        ? css`
            transition-delay: 8s;
          `
        : "";

    return (
      <Holder {...this.props} css={[initCss]}>
        <DotHolder className={isInverse ? "inverse" : ""}>
          <span
            className={`dot ${screen === SCREENS.GET_STARTED ? "active" : ""}`}
          />
          <span
            className={`dot ${
              screen === SCREENS.LANGUAGE_SELECTION ? "active" : ""
            }`}
          />
          <span
            className={`dot ${
              screen === SCREENS.CATEGORY_SELECTION ? "active" : ""
            }`}
          />
        </DotHolder>
        <div>
          <NextButton
            data-testid="navigator-btn"
            onClick={this.changeScreen}
            className={isInverse ? "inverse" : ""}
            data-screen={screen}
          >
            {screen === SCREENS.GET_STARTED && t("Let’s Get Started")}
            {screen === SCREENS.LANGUAGE_SELECTION && t("Continue")}
            {screen === SCREENS.CATEGORY_SELECTION && t("Done")}
          </NextButton>
        </div>
      </Holder>
    );
  }
}

export default i18n.withTranslation("common")(StepNavigator);
