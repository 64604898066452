import { SCREENS, SCREEN_SWITCH_DURATION, d } from "./utils";
/** @jsxRuntime classic /
/* @jsx jsx */
import { css, jsx } from "@emotion/core";

import colors from "../../common/colors";
import i18n from "../../../../i18n";
import styled from "@emotion/styled";

const trans = {};
trans[SCREENS.SPLASH] = css`
  bottom: -100%;
`;
trans[SCREENS.GET_STARTED] = css`
  bottom: 0;
`;
trans[SCREENS.LANGUAGE_SELECTION] = css`
  bottom: 0;
`;

const Holder = styled.div`
  position: absolute;
  left: ${d.sideGap};
  right: ${d.sideGap};
  bottom: -200%;
  ${p => trans[p.screen] || trans[SCREENS.SPLASH]}
  height: 327px;
  z-index: 5;
  transition: ${SCREEN_SWITCH_DURATION / 2}ms ease-in-out;
  transition-property: bottom, height;
  overflow: hidden;
`;

const contentHolderTrans = {};
contentHolderTrans[SCREENS.GET_STARTED + 1] = css`
  top: -150%;
  /* opacity: 0; */
  transition: ${SCREEN_SWITCH_DURATION / 2}ms ease-in-out;
  transition-property: top, opacity;
`;

const ContentHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: ${d.sideGap};
  padding-top: 62px;
  ${p =>
    contentHolderTrans[p.screen] ||
    (p.screen > SCREENS.GET_STARTED &&
      contentHolderTrans[SCREENS.GET_STARTED + 1]) ||
    ""}
`;
const Title = styled.h1`
  font-size: 32px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -1.28px;
  text-align: left;
  color: ${colors.white};
  margin: 0;
  visibility: hidden;
`;
const Text = styled.h3`
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: ${colors.white};
  margin-top: 24px;
  font-weight: normal;
`;

const GetStarted = props => {
  const { t, screen, animationDelay = 0 } = props;
  const initCss =
    screen === SCREENS.GET_STARTED
      ? css`
          transition-delay: 8s;
        `
      : "";
  return (
    <Holder data-testid="get-started" {...props} css={[initCss]}>
      <ContentHolder screen={screen}>
        <Title>
          {t("Glance is not")}
          <br />
          {t("available for your")}
          <br />
          {t("lock screen")}
        </Title>
        <Text>
          {t("Continue on Glance Lite app to enjoy")}
          <br />
          {t("what's trending on Glance")}
        </Text>
      </ContentHolder>
    </Holder>
  );
};

export default i18n.withTranslation("common")(GetStarted);
