import styled from "@emotion/styled";
import React, { useState, useEffect, useRef } from "react";
import colors from "../../common/colors";
import i18n from "../../../../i18n";
import { keyframes } from "emotion";
import ErrorIcon from "./ErrorIcon";
import font from "../../common/font";

const Container = styled.section`
  background: ${colors.white};
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  position: absolute;
`;

const sliderAnimation = keyframes`
  0% {
    min-height: 100%;
    bottom: 0px;
  }
  50% {
    bottom: 85px;
  }
  70% {
    min-height: 0%;
    bottom: 85px;
  }
  100% {
    min-height: 0%;
    bottom: 85px;
  }
`;

const bgAnimation = keyframes`
  0% {
    top: 0;
  }
  70% {
    top: 100%;
  }
  100% {
    top: 100%;
  }
`;

const RedBGForContainer = styled.div`
  background: ${colors.redRibbon};
  background-image: linear-gradient(to bottom, #fc024d, #a7002e);
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: ${bgAnimation} 1.5s;
  animation-delay: 3000ms;
  animation-fill-mode: forwards;
  z-index: 4;
`;

const SlideContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  height: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  position: absolute;
  z-index: 4;
  justify-content: center;
  background: transparent;
  animation: ${sliderAnimation} 1.5s;
  animation-delay: 3000ms;
  animation-fill-mode: forwards;
  transition: bottom 1400ms ease-out;
  &.hide-this {
    bottom: -100%;
  }
`;

const SlideContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 40px 60px;
`;

const Heading = styled.div`
  font-family: Urbanist;
  width: 250px;
  font-size: ${parseInt(font.fontSmall) * 2 + 2}px;
  font-weight: 800;
  line-height: 1.23;
  letter-spacing: -1.04px;
  text-align: center;
  color: ${colors.white};
  padding-bottom: 14px;
  transition: top 3s;
  margin-top: 3px;
  margin: 0 auto;
`;

const HomeLanding = ({ t }) => {
  const heading = t("Glance is not available for your lock screen");
  const [slideContentHeight, setSlideContentHeight] = useState();
  const supportedActions = useRef();

  useEffect(() => {
    if (supportedActions.current) {
      setSlideContentHeight(supportedActions.current.clientHeight);
    }
  }, [supportedActions]);

  return (
    <Container>
      <RedBGForContainer />
      <SlideContainer>
        <SlideContent ref={supportedActions}>
          <ErrorIcon width={"55px"} height={"55px"} margin={"0 auto"} />
          <Heading
            data-testid="home-landing"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        </SlideContent>
      </SlideContainer>
    </Container>
  );
};

export default i18n.withTranslation("common")(HomeLanding);
