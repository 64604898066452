import { SCREENS, SCREEN_SWITCH_DURATION, d } from "./utils";
/** @jsxRuntime classic /
/* @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";

import React from "react";
import colors from "../../common/colors";
import i18n from "../../../../i18n";
import languages from "./languages";
import styled from "@emotion/styled";
import { transparentize } from "polished";

const outOfScreen = css`
  top: 100%;
`;

const holderTrans = {};
holderTrans[SCREENS.LANGUAGE_SELECTION] = css`
  top: 0;
`;
holderTrans[SCREENS.CATEGORY_SELECTION] =
  holderTrans[SCREENS.LANGUAGE_SELECTION];

const Holder = styled.div`
  position: absolute;
  left: ${d.sideGap};
  right: ${d.sideGap};
  bottom: 0;
  ${p => holderTrans[p.screen] || outOfScreen}
`;
const Background = styled.div`
  background-image: url("https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/bg-language.jpg");
  background-size: 100%;
  background-position: center bottom;
  border-radius: 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 180vh;
  height: 50vh;
  transition: ${SCREEN_SWITCH_DURATION}ms ease-in-out;
  transition-property: top, height, opacity;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10vh;
  transition: ${SCREEN_SWITCH_DURATION}ms height;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  overflow: hidden;
`;
const TitleHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10%;
`;
const LangBigText = styled.h1`
  text-shadow: 0 0 10px ${transparentize(0.2, colors.black)};
  font-family: Urbanist;
  font-size: 62px;
  font-weight: 800;
  line-height: 0.32;
  letter-spacing: -3.72px;
  text-align: left;
  color: ${colors.white};
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 60px 20px;
`;
const LangText = styled.h2`
  font-family: Urbanist;
  font-size: 32px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -1.28px;
  text-align: left;
  color: ${colors.black};
  margin: 0;
`;
const ButtonLater = styled.button`
  border: 0;
  padding: 0;
  outline: 0;
  background-color: transparent;
  text-decoration: underline;
  color: ${colors.silver};
  font-size: 12px;
  font-weight: 800;
  line-height: 1.17;
  white-space: nowrap;
`;
const LangButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LangSelect = styled.div`
  text-align: center;
  button {
    padding: 10px;
    background-color: ${colors.silver};
    border: 0;
    outline: 0;
    border-radius: 20px;
    min-width: 61px;
    min-height: 61px;
    width: 100%;
    overflow: hidden;
    position: relative;
    &:after, &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      border-radius: 50%;
    }
    &:before {
      background-image: url("${p => p.image}");
      background-size: 67% auto;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
    }
    &:after {
      background-color: ${colors.glanceRed};
      transform: scale(0);
      transition: 0.5s transform;
      z-index: 0;
    }
  }
  label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.21;
    text-align: center;
    color: ${colors.silver};
    display: block;
    margin-top: 10px;
    transition: 0.5s color;
  }
  &.selected {
    button:after {
      transform: scale(1.5);
    }
    label {
      color: ${colors.black};
    }
  }
`;

const LangButton = styled.button``;
const LangLabel = styled.label``;

const backgroundTransitions = {};
backgroundTransitions[SCREENS.LANGUAGE_SELECTION] = css`
  top: -36vh;
  height: 80vh;
`;
backgroundTransitions[SCREENS.CATEGORY_SELECTION] = css`
  top: -80vh;
  opacity: 0;
`;

const helloFadeInAnimations = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const helloFadeOutAnimations = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const contentTransitions = {};
contentTransitions[SCREENS.LANGUAGE_SELECTION] = css`
  height: 40vh;
`;
contentTransitions[SCREENS.CATEGORY_SELECTION] = css`
  height: 0;
`;

const LanguageSelector = props => {
  const { t, screen, setScreen, preSelectedLanguages, setLanguages } = props;
  const [selectedLanguages, setSelectedLanguages] = React.useState([]);
  const [helloText, setHelloText] = React.useState(["hello", ""]);

  const changeHelloText = React.useCallback(
    selectedLangs => {
      const lang = languages.find(
        lang => lang.langName === selectedLangs[selectedLangs.length - 1]
      );
      let [prevHello, currentHello] = helloText;
      prevHello = currentHello;

      currentHello = lang ? lang.helloText : "";

      setHelloText([prevHello, currentHello]);
    },
    [helloText]
  );

  const onLanguageSelect = React.useCallback(
    lang => {
      return () => {
        let langs = [...selectedLanguages];
        if (langs.includes(lang)) {
          langs.splice(langs.indexOf(lang), 1);
        } else {
          langs.push(lang);
        }

        langs.unshift(languages[0].langName);
        langs = [...new Set(langs)];
        setSelectedLanguages(langs);
        setLanguages(langs);
        changeHelloText(langs);
      };
    },
    [changeHelloText, selectedLanguages, setLanguages]
  );

  React.useEffect(() => {
    onLanguageSelect(languages[0].langName)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (screen > SCREENS.LANGUAGE_SELECTION + 1) return null;
  return (
    <Holder data-testid="language-selector" {...props}>
      <Background screen={screen} css={backgroundTransitions[screen]}>
        {helloText[0] !== helloText[1] && (
          <LangBigText
            key={helloText[0]}
            css={css`
              animation: ${helloFadeOutAnimations} 1s ease;
              animation-fill-mode: forwards;
            `}
          >
            {helloText[0]}
          </LangBigText>
        )}
        <LangBigText
          key={helloText[1]}
          css={css`
            animation: ${helloFadeInAnimations} 1s ease;
            animation-fill-mode: forwards;
          `}
        >
          {helloText[1]}
        </LangBigText>
      </Background>
      <ContentHolder css={contentTransitions[screen]}>
        <TitleHolder>
          <LangText>{t("Choose Your Languages")}</LangText>
          <div>
            <ButtonLater onClick={() => setScreen(SCREENS.CATEGORY_SELECTION)}>
              {t("Do it later")}
            </ButtonLater>
          </div>
        </TitleHolder>
        <LangButtonHolder>
          {languages.map(lang => {
            const { langName, ...rest } = lang;
            const name = lang.langName;
            return (
              <LangSelect
                {...rest}
                key={name}
                role="button"
                className={selectedLanguages.includes(name) ? "selected" : null}
                onClick={onLanguageSelect(name)}
              >
                <LangButton id={name} />
                <LangLabel className="text" htmlFor={name}>
                  {lang.text}
                </LangLabel>
              </LangSelect>
            );
          })}
        </LangButtonHolder>
      </ContentHolder>
    </Holder>
  );
};

export default i18n.withTranslation("common")(LanguageSelector);
