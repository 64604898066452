import { SCREENS, SCREEN_SWITCH_DURATION } from "./utils";
/** @jsxRuntime classic /
/* @jsx jsx */
import { css, jsx } from "@emotion/core";

import React from "react";
import colors from "../../common/colors";
import { getViewPortDimensions } from "../../utils/browser";
import styled from "@emotion/styled";

const trans = {};

trans[SCREENS.SPLASH] = css`
  transform: translate(-50%, 60%);
`;
trans[SCREENS.GET_STARTED] = css`
  transform: translate(-50%, 0%) rotate(180deg);
`;
trans[SCREENS.LANGUAGE_SELECTION] = css`
  transform: translate(-50%, -120%);
`;
trans[SCREENS.CATEGORY_SELECTION] = css`
  transform: translate(-50%, 10%);
`;
trans[SCREENS.HIGHLIGHT] = css`
  transform: translate(-50%, -50%) scale(0.85);
  width: ${getViewPortDimensions().vw};
  height: ${getViewPortDimensions().vw};
`;

const Holder = styled.div`
  background-image: url("/static/images/buzzstorm/bg-circle.svg");
  background-size: 100%;
  position: absolute;
  width: ${getViewPortDimensions().vh};
  height: ${getViewPortDimensions().vh};
  ${p =>
    p.screen === SCREENS.GET_STARTED
      ? css`
          top: calc(${getViewPortDimensions().vh} - 295px);
        `
      : css`
          top: 50%;
        `}
  left: 50%;
  z-index: 3;
  transition: transform
    ${p =>
      p.screen === SCREENS.GET_STARTED
        ? SCREEN_SWITCH_DURATION / 2
        : SCREEN_SWITCH_DURATION}ms
    ease-in-out;
  transition-property: transform, width, height;
  transform-origin: center;
  ${p => trans[p.screen] || trans[SCREENS.SPLASH]}

  &:before,
  &:after {
    content: "";
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-image: linear-gradient(
      to bottom,
      ${colors.redRibbon},
      rgba(0, 0, 0, 0)
    );
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }
  &:after {
    background-image: linear-gradient(
      to bottom,
      rgba(249, 17, 81, 0.3),
      ${colors.redRibbon} 57%
    );
  }
`;

const CircleTransition = props => {
  const { screen, animationDelay = 0 } = props;
  const anim = trans[screen];

  const initCss =
    screen === SCREENS.GET_STARTED
      ? css`
          transition-delay: 1000ms;
        `
      : "";
  return (
    <Holder
      data-testid="circle-transition"
      screen={screen}
      css={[initCss, anim]}
    />
  );
};

export default React.memo(CircleTransition);
