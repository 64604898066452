import { SCREENS, SCREEN_SWITCH_DURATION, d, noop } from "./utils";
/** @jsxRuntime classic /
/* @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";

import GlanceLogo from "./GlanceLogo";
import React from "react";
import colors from "../../common/colors";
import styled from "@emotion/styled";

// const trans = {};
// trans[SCREENS.SPLASH] = css`
//   transform: scale(0.75);
//   border-radius: 30px;
// `;
// trans[SCREENS.HIGHLIGHT] = css`
//   transform: scale(1);
//   border-radius: 0;
//   transition: top 1s, transform, 1s;
// `;
// trans[SCREENS.HIGHLIGHT + 1] = css`
//   transform: scale(1);
// `;

const holderAnimation = {};
holderAnimation[SCREENS.HIGHLIGHT] = keyframes`
  50%, 90% {
    transform: scale(0.7) translateY(0);
    border-radius: 30px;
  }
  100% {
    transform: scale(1) translateY(0);
    border-radius: 0;
  }
`;

const Holder = styled.div`
  position: absolute;
  border-radius: 30px;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 6;
  /* left: calc(${d.sideGap} + 10%);
  right: calc(${d.sideGap} + 10%);
  height: calc(80% - 15%); */
  transform: scale(0.7) translateY(150%);
  transform-origin: center;
  animation-name: ${p => holderAnimation[p.screen] || "none"};
  animation-duration: ${SCREEN_SWITCH_DURATION + 500}ms;
  animation-delay: ${p => p.animationDelay || 0}ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`;

const Image = styled.div`
  background: linear-gradient(
        rgba(0, 0, 0, 0.29),
        rgba(0, 0, 0, 0) 18%,
        rgba(0, 0, 0, 0) 53%,
        rgba(0, 0, 0, 0.76)
      )
      center center / cover no-repeat;
  background-image: url("${p => p.url}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
`;

const moveLogoLeft = css`
  fill: ${colors.white};
  width: 70px;
  height: 25px;
  top: ${d.glanceTopGap};
  left: ${d.glanceSideGap};
  transform: scale(1) translate(0, 0);
`;

const logoAnimation = {};
logoAnimation[SCREENS.HIGHLIGHT] = keyframes`
  90% {
    top: 50%;
    left: 50%;
    transform: scale(2.5) translate(-20%, 50%);
  }
  100% {
    ${moveLogoLeft}
  }
`;

const Logo = styled(GlanceLogo)`
  width: 70px;
  height: 25px;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  fill: ${colors.white};
  transform: scale(2.5) translate(-20%, 50%);
  animation-name: ${p => logoAnimation[p.screen] || "none"};
  animation-duration: ${SCREEN_SWITCH_DURATION + 500}ms;
  animation-delay: ${p => p.animationDelay || 0}ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`;

const InitialGlance = props => {
  const {
    screen,
    animationDelay = 0,
    glance = null,
    onAnimationEnd = noop
  } = props;

  const [unmount, setUnmount] = React.useState(false);
  const onAnimEnd = React.useCallback(() => {
    setTimeout(() => {
      onAnimationEnd();
      setUnmount(true);
    }, 500);
  }, [onAnimationEnd]);
  const initCss =
    screen === SCREENS.GET_STARTED
      ? css`
          transition-delay: ${animationDelay}ms;
        `
      : "";
  if (glance && !unmount) {
    return (
      <Holder {...props} css={[initCss]} onAnimationEnd={onAnimEnd}>
        <Image data-testid="initial-glance-image" url={glance.image.url} />
        <Logo screen={screen} />
      </Holder>
    );
  }
  return null;
};

export default InitialGlance;
