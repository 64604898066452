import React, { useEffect, useState } from "react";
import { getGlanceIds, getGlances } from "../services";
import {
  getGlancesWithBubblesForRender,
  getFixedGlances,
  useGlances
} from "../utils/useGlances";
import "../common/globals";
import CategorySelector from "../components/Splash/CategorySelector";
import CircleTransition from "../components/Splash/CircleTransition";
import GetStarted from "../components/Splash/GetStarted";
import GlanceListWithHighlights from "../components/GlanceListWithHighlights";
import { Global } from "@emotion/core";
import Head from "next/head";
// import HighlightTransition from "../components/Splash/HighlightTransition";
import ImagePallete from "../components/Splash/ImagePallete";
import InitialGlance from "../components/Splash/InitialGlance";
import LanguageSelector from "../components/Splash/LanguageSelector";
import Logo from "../components/Splash/Logo";
import { SCREENS } from "../components/Splash/utils";
import StepNavigator from "../components/Splash/StepNavigator";
import { getUrbanist } from "../common/styles";
import styled from "@emotion/styled";
import HomeLanding from "../components/BuzzStorm/HomeLanding";
import GlanceLoading from "../components/BuzzStorm/GlanceLoading";

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-width: 450px;
  margin: auto;
  background-color: #fff;
  overflow: hidden;
`;

const splashImages = [
  "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/splash/7.jpg"
];

const animationDuration = 5000;
const animationDelay = 0;
let glancesUpdated = false;

function Home(props) {
  const [screen, setScreen] = React.useState(SCREENS.SPLASH);
  const [hideGetStarted, setHideGetStarted] = useState(false);
  const [languages, setLanguages] = useState(["en"]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [enableAutoPlay, setEnableAutoPlay] = useState(false);
  const [splashScreenCompleted, setSplashScreenCompleted] = useState(false);
  const [pauseAutoPlay, setPauseAutoPlay] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [showLanding, setShowLanding] = useState(true);
  const [isLoadingFadeout, setLoadingFadeout] = useState(false);
  const [animationEnd, setAnimationEnd] = useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setScreen(SCREENS.GET_STARTED);
    }, 500);

    setTimeout(() => {
      setLoadingFadeout(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }, 4 * 1000);
  }, []);

  useEffect(() => {
    if (
      screen === SCREENS.LANGUAGE_SELECTION ||
      screen === SCREENS.CATEGORY_SELECTION
    ) {
      setTimeout(() => {
        setShowLanding(false);
      }, 100);
      setTimeout(() => {
        setHideGetStarted(true);
      }, 800);
    }
    if (screen === SCREENS.HIGHLIGHT) {
      setTimeout(() => {
        setSplashScreenCompleted(true);
      }, 1000);
    }
  }, [screen]);

  const afterSplashDelay = animationDuration - 300;

  if (screen === SCREENS.HIGHLIGHT && !glancesUpdated) {
    // update props to get latest categorised glances
    props.loadIntialPropsOnce();
    glancesUpdated = true;
  }

  const state = useGlances(props);
  // const glances = getGlancesWithBubbles(state.glances, props.onb === "1", true);
  let fixedGlances = getFixedGlances();
  const glancesForRender = getGlancesWithBubblesForRender(state.glances);
  const initial = fixedGlances ? fixedGlances[0] : undefined;
  if (fixedGlances) {
    fixedGlances = glancesForRender
      ? fixedGlances[1].concat(glancesForRender)
      : fixedGlances[1];
  }

  return (
    <>
      <Head>
        {/* Spash screen images - preload */}
        {splashImages.map(src => (
          <link key={src} rel="preload" as="image" href={src} />
        ))}

        <meta
          key="description"
          property="description"
          content={`Discover what's trending in fashion, entertainment, sports, news, travel & much more`}
        />
      </Head>
      <Global
        styles={`* {box-sizing: border-box;} #__next { height: 100%;}${getUrbanist()}html, body{ padding: 0;margin: 0 auto;background-color: #000; max-width: 450px;} `}
      />

      {showLanding &&
        (isLoading ? (
          <GlanceLoading isFadingOut={isLoadingFadeout} />
        ) : (
          <HomeLanding />
        ))}
      <Container suppressHydrationWarning={true}>
        {process.browser && !splashScreenCompleted && (
          <>
            <Logo
              animationDuration={animationDuration}
              animationDelay={animationDelay}
              screen={screen}
            />
            {/*<CircleBackground
              animationDuration={animationDuration}
              animationDelay={animationDelay}
              screen={screen}
              setScreen={setScreen}
            />*/}
            <ImagePallete
              images={splashImages}
              animationDuration={animationDuration}
              animationDelay={animationDelay}
              screen={screen}
            />

            {/* steps */}
            <CircleTransition
              animationDelay={afterSplashDelay}
              screen={screen}
            />
            {!hideGetStarted && (
              <GetStarted animationDelay={afterSplashDelay} screen={screen} />
            )}
            <LanguageSelector
              screen={screen}
              setScreen={setScreen}
              preSelectedLanguages={languages}
              setLanguages={setLanguages}
            />
            <CategorySelector
              screen={screen}
              setScreen={setScreen}
              setParentSelectedCategories={setSelectedCategories}
            />

            <StepNavigator
              animationDelay={afterSplashDelay}
              screen={screen}
              setScreen={setScreen}
              languages={languages}
              selectedCategories={selectedCategories}
            />
          </>
        )}
        {screen === SCREENS.HIGHLIGHT && (initial || fixedGlances) && (
          <>
            <InitialGlance
              screen={screen}
              glance={initial && initial.highlights[0]}
              onAnimationEnd={() => setAnimationEnd(true)}
            />
            {fixedGlances && animationEnd && (
              <GlanceListWithHighlights
                glances={fixedGlances}
                fromHome={true}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
}

const withGlance = Component => props => {
  const [initialPropsLoaded, setInitialPropsLoaded] = React.useState(false);
  const [initialProps, setInitialProps] = React.useState({
    ids: [],
    glances: []
  });

  const loadIntialPropsOnce = React.useCallback(async () => {
    if (!initialPropsLoaded) {
      const idsList = await getGlanceIds();
      const ids = idsList.map(id => id.glanceId);
      const glances = await getGlances({ ids });
      setInitialPropsLoaded(true);
      setInitialProps({ glances, ids });
    }
  }, [initialPropsLoaded]);
  return (
    <Component {...initialProps} loadIntialPropsOnce={loadIntialPropsOnce} />
  );
};
export default withGlance(Home);
